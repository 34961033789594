.e_mb {
  contain: content;
  overflow: hidden;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80%;
  background-color: rgb(var(--g-90));
}

.e_nb {
  min-height: 7.5rem;
}

.e_ob {
  min-height: 15.625rem;
}

@media (min-width: 64rem) {

.e_nb {
    min-height: 15.625rem
}
}

.e_Ec {
  width: 100%;
}

  .e_Ec {
  --container-width: var(--wrapper-inner-width);
}

  /* variables */

  @media (min-width: 64rem) {.e_Ec {
    max-width: 51.25rem;
    margin-left: auto;
    margin-right: auto
}.e_Ec {
    --container-width: 51.25rem
}
}

.e_t {
  margin-top: 1.25rem;
}

.e_u {
  margin: 1.25rem 0;
}

.e_Jo {
  display: flex;
  row-gap: 2.5rem;
  position: relative;
  flex-direction: column;
}

  .e_Jo .e_Ko {
    height: 100vh;
  }

  .e_Jo .e_Lo {
    position: sticky;
    top: 4rem;
  }

.e_gd {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.e_id {
  top: var(--top);
  z-index: 8;
  transition: transform 0.2s;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  transform: translateY(var(--translate-complex));

  --top: 3.125rem;
  --translate: 0rem;
  --translate2: 0rem;
  --translate-complex: calc(var(--translate) + var(--translate2));
}

  .e_id.e_jd {
    --translate2: 1.875rem;
  }

  .e_id.e_kd:not(.e_ld) {
    --translate: 0rem;
  }

  .e_id:not(.e_md) {
    --translate: 1.875rem;
  }

  .e_id.e_md {
    --translate: -0.625rem;
  }

  .e_id:not(.e_nd) {
    --translate: 0rem;
  }

  .e_id.e_od {
    --top: 5.125rem;
  }

  @media (min-width: 64rem) {.e_id {
    --top: 4.375rem
}

    .e_id.e_od {
      --top: 8.25rem;
    }

    .e_id.e_pd:not(.e_md) {
      --translate: 1.875rem !important;
    }
}

  @media (min-width: 80rem) {

  .e_id.e_jd {
      --translate2: 0
  }
    .e_id.e_md {
      --translate: 1.875rem;
    }
}

.e_Ao,
.e_Bo {
  display: none;
}

.e_Bo {
  --container-width: 0;
}

/* variables */

.e_Ao {
  --container-width: 0;
}

/* variables */

.e_Co {
  /* variables */
  --container-width: var(--wrapper-inner-width);
}

@media (min-width: 64rem) {.e_Do {
    display: grid;
    grid-column-gap: 1.875rem;
    -moz-column-gap: 1.875rem;
         column-gap: 1.875rem;
    grid-template-columns: 1fr 18.75rem
}

.e_Bo {
    display: block
}

.e_Bo {
    --container-width: 18.75rem
}

.e_Co {
    --container-width: calc(var(--wrapper-inner-width) - 20.625rem)
}
}

@media (min-width: 80rem) {.e_Do {
    display: grid;
    grid-template-columns: minmax(0, 270fr) minmax(0, 660fr) 18.75rem
}

.e_Ao {
    display: block
}

.e_Ao {
    --container-width: calc(calc(calc(var(--wrapper-inner-width) - 20.625rem) / 960) * 270)
}

.e_Co {
    --container-width: calc(calc(calc(var(--wrapper-inner-width) - 20.625rem) / 960) * 660)
}
}

.e_Dd {
  display: none;
}

.e_Dd {
  --container-width: 0;
}

/* variables */

.e_Ed {
  /* variables */
  --container-width: var(--wrapper-inner-width);
}

@media (min-width: 64rem) {.e_Fd {
    display: grid;
    grid-gap: 1.875rem 6.25rem;
    gap: 1.875rem 6.25rem;
    grid-template-columns: minmax(0, 1fr) 18.75rem
}

.e_Dd {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    row-gap: 1.25rem
}

.e_Dd {
    --container-width: 18.75rem
}

.e_Ed {
    --container-width: calc(var(--wrapper-inner-width) - 25rem)
}
}

.e_cd {
  display: flex;
  -moz-column-gap: 0.375rem;
       column-gap: 0.375rem;
  align-items: center;
  flex-wrap: wrap;
}

.e_dd {
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: -0.00625rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.3125rem 0;
  color: rgb(var(--p-40));
}

.e_ed {
  color: rgb(var(--g-50));
}

.e_fd {
  width: 1rem;
  height: 1rem;
  color: rgb(var(--p-40));
}

.e_-c {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.e_ad {
  color: rgb(var(--g-25));
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.01875rem;
}

.e_bd {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.e_hd {
  min-height: 27.5rem;
}

.e_xo {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

  .e_xo:hover .e_yo {
      opacity: 1;
      pointer-events: all;
    }

  .e_xo:not(:last-of-type)::after {
    margin-right: 0.3125rem;
    content: ',';
  }

.e_yo {
  top: 100%;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 100vw;

  transition: opacity 0.2s;
}

.e_xo:focus-within ~ .e_zo {
  opacity: 1;
  pointer-events: all;
}

.e_zo {
  opacity: 0;
  pointer-events: none;
  background-color: rgba(var(--b), 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;

  transition: opacity 0.2s;
}

@media (min-width: 40rem) {

.e_yo {
    width: 20.625rem
}

.e_zo {
    display: none
}
}

.e_oo {
  position: relative;
}

.e_po {
  padding: 0.25rem 0.25rem 0.25rem 0;
}

.e_qo {
  color: rgb(var(--g-25));
  width: 1rem;
  height: 1.25rem;
}

.e_ro {
  width: auto;
  border: 0.0625rem solid rgba(var(--b), 0.05);
  transform: scale(0.55) translateY(-0.125rem);
  background-color: rgb(var(--w));
  padding: 0.25rem;
  top: 100%;
  transition: opacity 0.2s, transform 0.2s;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  transform-origin: 0 0;
  left: 0;
}

.e_ro .vf-share-bar-square {
    width: auto !important;
  }

.e_so {
  transform: scale(0.6) translateY(0);
  opacity: 1;
  pointer-events: auto;
}

@media (min-width: 40rem) {

.e_ro {
    transform: scale(0.55) translateY(-0.125rem) translateX(-50%)
}

.e_so {
    transform: scale(0.6) translateY(0) translateX(-50%)
}
}

.e_0c {
  display: flex;
  row-gap: 0.25rem;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  flex-direction: column;
}

.e_1c {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}

.e_2c {
  color: rgb(var(--p-40));
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: -0.00625rem;
}

.e_3c {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgb(var(--g-25));
  letter-spacing: -0.00625rem;
}

.e_4c {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgb(var(--g-65));
  letter-spacing: -0.00625rem;
}

.e_5c {
  display: none;
}

.e_6c {
  height: 1.25rem;
}

.e_7c {
  color: rgb(var(--g-25));
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.e_8c {
  padding-left: 0.625rem;
}

.e_9c {
  display: none;
}

@media (min-width: 40rem) {.e_0c {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center
}

.e_9c {
    display: block
}
}

@media (min-width: 64rem) {

.e_8c {
    padding-left: 1.875rem
}
}

.e_rd {
  gap: 1.25rem;
}

.e_sd {
  gap: 0.3125rem;
  display: flex;
  flex-wrap: wrap;
}

.e_td {
  padding: 0.3125rem 0;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: rgb(var(--g-40));
}

.e_td:hover {
    text-decoration: underline;
  }

.e_td:not(:last-child)::after {
    content: ',';
  }

.e_Sd {
  font-size: 2rem;
  line-height: 2.375rem;
  font-weight: 600;
  letter-spacing: -0.00625rem;
}

.e_Td {
  font-size: 1rem;
  word-break: break-word;
  line-height: 1.5rem;
  font-weight: 500;
  letter-spacing: -0.00625rem;
  color: rgb(var(--g-25));
}

@media (min-width: 64rem) {.e_Sd {
    font-size: 2.5rem;
    line-height: 2.875rem
}

.e_Td {
    font-size: 1.25rem;
    line-height: 1.625rem;
    letter-spacing: -0.03125rem
}
}

.e_qd {
  margin-top: 0.5rem;
}

.e_ye {
  display: grid;
  grid-gap: 0.1875rem;
  gap: 0.1875rem;
}

.e_ze {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: bold;
}

.e_Ae {
  width: 3.8125rem;
  height: 0.5rem;
  stroke: rgb(var(--p-70));
  fill: transparent;
  margin: 0.3125rem 0;
}

.e_Be {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  overflow-wrap: anywhere;
}

.e_Be:not(.e_Ce) {
    opacity: 0.6;
    transition: opacity 0.2s;
  }

.e_Be:hover {
    opacity: 1;
  }

.e_Be.e_Ce {
    display: flex;
    align-items: center;
    -moz-column-gap: 0.625rem;
         column-gap: 0.625rem;
  }

.e_De {
  width: 1.3125rem;
  height: 0.875rem;
  color: rgb(var(--p-70));
}

@media (min-width: 64rem) {.e_ye {
    gap: 0.625rem
}

.e_ze {
    font-size: 1.5rem;
    line-height: 2rem
}

.e_Ae {
    margin: 0
}

.e_Be {
    font-size: 1.375rem;
    font-weight: 400
}
}

@media (min-width: 80rem) {
    .e_Be.e_Ce {
      display: block;
      position: relative;
    }

.e_De {
    position: absolute;
    left: -1.75rem;
    top: 0.3125rem
}
}

.e_w {
  margin-top: 0.9375rem;
}

.e_x {
  margin-top: 1.875rem;
}

/* min-height: rem(142); */

.e_y {
  margin-bottom: 0.625rem;
}

.e_z {
  margin: 1.25rem 0;
}

.e_A {
  margin-top: 1.875rem;
}

.e_B {
  position: sticky;
  top: 7.5rem;
}

@media (min-width: 40rem) {

.e_x {
    contain: none !important;
    overflow: visible !important;
    margin-bottom: 1.875rem;
    padding-bottom: 1.875rem
}

.e_A {
    margin-top: 2.5rem
}
}

@media (min-width: 64rem) {

.e_y {
    margin-bottom: 0.9375rem
}
}

@media (min-width: 80rem) {

.e_B {
    top: 8.75rem
}
}

.e_C {
  margin: 0.75rem 0;
}

.e_D {
  display: none;
}

@media (min-width: 64rem) {

.e_D {
    display: block
}

.e_E {
    margin-top: 1.25rem
}
}


.e_Zc {
  contain: content;
  display: grid;
  grid-row-gap: 0.9375rem;
  row-gap: 0.9375rem;
}

.e__c {
  background-color: transparent;
}

@media (min-width: 48rem) {.e_Zc {
    row-gap: 1.25rem
}
}

.e_v {
  margin: 0.9375rem 0 0.625rem 0;
}@media (min-width: 48rem) {.e_v {
    margin-top: 1.5625rem
}
}

